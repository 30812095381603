<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center v-if="reviews">
      <v-flex xs12 pa-6>
        <span
          style="
            color: #005f32;
            font-size: 19px;
            border-bottom: 2px solid #005f32;
          "
          class="popsemibold"
          >What our customer says</span
        >
      </v-flex>
      <v-flex xs12 sm10 lg8 pt-4>
        <v-layout wrap justify-center v-if="reviews">
          <v-flex xs12 pa-2 v-for="(item, i) in reviews" :key="i">
            <router-link :to="'/product/' + item.product">
              <v-card outlined>
                <v-layout wrap justify-center fill-height>
                  <v-flex xs12 align-self-center pa-1>
                    <span class="popregular">
                      {{ item.comment }}
                    </span>
                  </v-flex>
                  <v-flex xs12 pa-1 align-self-center>
                    <v-rating
                      v-model="item.rating"
                      color="orange"
                      background-color="grey darken-1"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                      small
                      readonly
                    ></v-rating>
                  </v-flex>

                  <v-flex
                    xs12
                    pa-2
                    align-self-center
                    text-right
                    v-if="item.user"
                  >
                    <span
                      class="popregular"
                      style="color: grey; font-size: 12px"
                    >
                      <i
                        >{{ item.user.firstname }}&nbsp;{{
                          item.user.lastname
                        }}</i
                      >
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </router-link>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="totalCount > 0" py-16>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            :color="appColor"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      value: null,
      timeout: 5000,
      msg: null,
      showByIndex: null,
      products: [],
      show: [],
      searchKey: null,
      zIndex: 0,
      reviews: null,
      currentPage: 1,
      pages: 0,
      pageCount: 0,
      totalCount: 0,
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/view/all/reviews",
        data: {
          page: this.currentPage,
          limit: 15,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.reviews = response.data.data;
            this.totalCount = response.data.totalLength;
            if ((response.data.totalLength / response.data.limit) < 1) {
              this.pages = 1
            } else {
              this.pages = response.data.totalLength / response.data.limit;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.rate {
  font-family: sofiaProLight;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
}
.name {
  font-family: sofiaProSemiBold;
  font-size: 20px;
  color: #1a1919;
}
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}
.v-select__selections {
  min-height: 10px;
}

/* .owl-item.active > div:after {
  content: 'active';
}
.owl-item.center > div:after {
  content: 'center';
}
.owl-item.active.center > div:after {
  content: 'active center';
}
.owl-item > div:after {
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
} */

.owl-carousel {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}
</style>
